<template>
  <div>
    <!-- <ui-icon-button icon="arrow_back"></ui-icon-button> -->
    <ui-button
      style="margin-top: 10px"
      :disabled="loadingSubmit"
      icon="arrow_back"
      @click="goBack"
    >
      Back
    </ui-button>
    <ui-form v-if="!loadingRetention">
      <div style="padding-left: 20px">
        <div
          style="
            padding: 12px;
            border-bottom: 1px solid lightgray;
            margin-top: 20px;
          "
        >
          Environment / retention
        </div>
      </div>
      <ui-grid class="config-grid">
        <ui-grid-cell class="config-cell" columns="4" :fullwidth="true">
          <ui-textfield
            :min="1"
            suffixText="hrs"
            input-type="number"
            required
            helper-text-id="pw-validation-msg"
            v-model="retention.frequency3s"
          >
            Frequency 3s
          </ui-textfield>
        </ui-grid-cell>
        <ui-grid-cell class="config-cell" columns="4" :fullwidth="true">
          <ui-textfield
            :min="1"
            suffixText="hrs"
            input-type="number"
            required
            helper-text-id="pw-validation-msg"
            v-model="retention.frequency1m"
          >
            Frequency 1m
          </ui-textfield>
        </ui-grid-cell>
        <ui-grid-cell class="config-cell" columns="4" :fullwidth="true">
          <ui-textfield
            :min="1"
            suffixText="hrs"
            input-type="number"
            required
            helper-text-id="pw-validation-msg"
            v-model="retention.frequency1h"
          >
            Frequency 1h
          </ui-textfield>
        </ui-grid-cell>
        <ui-grid-cell class="config-cell" columns="3">
          <ui-button
            :disabled="loadingSubmit"
            raised
            icon="save"
            @click="retentionSubmit"
          >
            Save changes
          </ui-button>
        </ui-grid-cell>
      </ui-grid>
    </ui-form>
    <div v-else>
      <ui-spinner
        :active="true"
        style="
          top: 50%;
          left: 50%;
          position: absolute;
          transform: translate(-50%, -50%);
        "
      ></ui-spinner>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loadingRetention: false,
      loadingSubmit: false,
      environment: null,
      retention: {
        frequency3s: 24,
        frequency1m: 168,
        frequency1h: 8760,
      },
    };
  },
  computed: {
    account: {
      get() {
        return this.$store.getters["getAccount"];
      },
      set(val) {
        console.log("val", val);
      },
    },
  },
  mounted() {
    this.loadDashboardConf();
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    retentionSubmit() {
      console.log("retentionSubmit", this.retention);
      const storeRetentionApi = `${this.$apiUrl}api/retention`;
      const params = {
        userId: this.account.localAccountId,
        envName: this.environment?.name,
        frequency3s: parseInt(this.retention.frequency3s),
        frequency1m: parseInt(this.retention.frequency1m),
        frequency1h: parseInt(this.retention.frequency1h),
      };

      this.loadingSubmit = true;
      this.axios
        .post(storeRetentionApi, params)
        .then((response) => {
          console.log("response ", response);
          this.loadingSubmit = false;
        })
        .catch((err) => {
          console.log("err ", err);
          this.loadingSubmit = false;

          this.$toast.error(
            `${err} - ${storeRetentionApi} with params ${JSON.stringify(
              params
            )}`,
            {
              position: "bottom",
              duration: false,
            }
          );
        });
    },
    async loadDashboardConf() {
      const dashboardConfApi = `${this.$apiUrl}api/dashboard`;
      const params = {
        userId: this.account.localAccountId,
      };

      this.loadingRetention = true;
      this.axios
        .get(dashboardConfApi, {
          params: params,
        })
        .then((response) => {
          console.log("response ", response);
          this.loadingRetention = false;
          if (response.data?.environments.length == 0 || response.data?.environments[0]?.retention == null) {
            this.$toast.error(
              `There's no retention specified`,
              {
                position: "bottom",
                duration: false,
              }
            );
          } else {
            this.environment = response.data?.environments[0];
            this.retention = this.environment?.retention;
          }
        })
        .catch((err) => {
          console.log("err ", err);
          this.loadingRetention = false;

          this.$toast.error(
            `${err} - ${dashboardConfApi} with params ${JSON.stringify(
              params
            )}`,
            {
              position: "bottom",
              duration: false,
            }
          );
        });
    },
  },
};
</script>

<style>
.config-grid .mdc-layout-grid__inner {
  align-items: end;
}
.config-cell {
  margin-bottom: 20px;
}
.config-cell .mdc-text-field--filled {
  width: 95%;
}
</style>